.nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #731700;
    border-bottom: 1px solid #e6e6e6;
    height: 50px;
}
a, .navLink { 
    text-decoration: none; 
    color: lightsteelblue;
    cursor: pointer;
    padding: 0.7rem 0.7rem;
}

a:hover, .navLink:hover {
    background-color: rgb(150, 150, 0);
    color: #731700;
}

.hidden {
    visibility: hidden;
}

.navLinkActive {
    background-color: rgb(190, 190, 0);
    color: rgb(67, 67, 67);
    visibility: visible !important;
}

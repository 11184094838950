.rightBar{
    background-color: #731700;
    color: rgb(190, 190, 0);
    width: 250px;
    float: right;
}
fieldset{
    margin: 15px;
}
table{
    width: 100%;
}
td{
    text-align: center;
    width: 50%;
}
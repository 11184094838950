.card{
    width: 200px;
    height: 300px;
    display: inline-block;
    margin: 20px;
    cursor: pointer;
    border-radius: 20px;
    border: solid rgb(67, 67, 67);
    background-color: white;
    opacity: 0.9;
    filter: grayscale(0.5);
}
img{
    height: 150px;
    width: 150px;
    margin-bottom: 20px;
}
h3{
    margin: 15px;
}
h2{
    color:lightslategray;
    padding: 15px;
}
.card:hover{
    background-color: white;
    transform: scale(1.05);
    box-shadow: 5px 5px grey;
    opacity: 1;
    transition: all 0.2s;
    filter: grayscale(0);
}
.containerLanding{
    background-image: url('../../../images/landingImage.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}
.button{
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7em;
    height: 2.2em;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
    box-shadow: 0px 0px 0.7em white;
    text-align: center;
    font-size: 2em;
    color: rgba(160, 82, 45, 0.8);
    font-weight: bolder;
    font-family: cursive;
    padding-top: 0.7em;
    transition: all .5s;
    cursor: pointer;
    text-decoration: none;
}
.button:hover{
    background: rgba(255, 255, 255, 0.9);
    transition: all .5s;
    color: rgba(160, 82, 45, 1);
}

